<template>
  <div class="media-page">
    <div class="box-filter">
      <ul class="box-filter__tabs">
        <li class="box-filter__tabs-item --title">
          Filtro
        </li>
        <li class="box-filter__tabs-item" :class="{ '--active': typeMedia == 'Source' }" @click="setMedia('Source')">
          Origem
        </li>
        <li class="box-filter__tabs-item" :class="{ '--active': typeMedia == 'Campaign' }" @click="setMedia('Campaign')">
          Campanha
        </li>
        <li class="box-filter__tabs-item" :class="{ '--active': typeMedia == 'Medium' }" @click="setMedia('Medium')">
          Mídia
        </li>
        <li class="box-filter__tabs-item" :class="{ '--active': typeMedia == 'Partner' }" @click="setMedia('Partner')">
          Parceiro
        </li>
        <li class="box-filter__tabs-item" :class="{ '--active': typeMedia == 'Coupon' }" @click="setMedia('Coupon')">
          Cupons
        </li>
      </ul>
      <StatusUpdateDados />
      <FilterDatepicker @getDataSelect="setDataFilter" />
    </div>
    <div class="primary">

      <!-- Pedidos Cupons -->
      <div class="box" v-if="typeMedia == 'Coupon'">
        <div class="w-100">
          <div class="box__header">
            <h2 class="panel__title panel__title--minor">
              Pedidos usando<br/>
              <b>Cupons</b>
            </h2>
          </div>
          <div class="box__body">
            <div class="panel__numbers">
              <div class="panel__numbers__data --today">
                <h3 class="panel__numbers__data__label">QUANTIDADE</h3>
                <h4 class="panel__numbers__data__number" v-if="ordersBilling.coupons">
                  <span class="number--normal">{{ordersBilling.coupons.count}}</span>
                </h4>
                <h4 class="panel__numbers__data__number" v-else>
                  <span class="number--normal">...</span>
                </h4>
              </div>

              <div class="panel__numbers__data --month">
                <h3 class="panel__numbers__data__label">FATURADO</h3>
                <h4 class="panel__numbers__data__number" v-if="ordersBilling.coupons">
                  <span class="number--normal">{{ordersBilling.coupons.spent | numberToReal }}</span>
                </h4>
                <h4 class="panel__numbers__data__number" v-else>
                  <span class="number--normal">...</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box box--charts" v-else>
        <HeaderGraphic :data="loadGraphicBar" />
        <div class="painel">
          <BarVertical
            v-if="midiaSource.media.data && midiaSource.media.data.length > 0"
            :chart-data="loadGraphicBar.datacollection"
          ></BarVertical>
          <p class="painel__empty" v-else>Sem dados</p>
        </div>
      </div>


      <div class="box table" v-if="typeMedia == 'Coupon'">
        <div class="box__header">
          <HeaderGraphic v-if="coupons.items && coupons.items.data" :data="couponTableData" />
          <a v-if="loadCouponTable.items.length > 0" class="btn-download" @click="downloadCSV()" download
            >Download CSV</a
          >
        </div>
        <div class="painel" v-if="loadCouponTable.items.length > 0">
          <b-table :items="loadCouponTable.items">
            <template #cell(cupom)="data">
              <span class="badge badge-outline">{{ data.item.Cupom }}</span>
            </template>
          </b-table>
          <paginate
            v-model="currentPage"
            :page-count="lastPage"
            :prev-text="'‹'"
            :next-text="'›'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          ></paginate>
        </div>
        <p class="empty" v-else>Sem dados</p>
      </div>

      <div class="box table" v-else>
        <div class="box__header">
          <HeaderGraphic v-if="midiaSource.media.data && midiaSource.media.data.length > 0" :data="tableData" />
          <p class="painel__empty" v-else>Sem dados</p>
          <a
            class="btn-download"
            v-if="midiaSource.media.data && midiaSource.media.data.length > 0"
            @click="downloadCSV()"
            download
          >
            Download CSV
          </a>
        </div>
        <div class="painel">
          <b-table :items="loadTable.items"></b-table>
          <paginate
            v-model="currentPage"
            :page-count="midiaSource.media_last_page"
            :prev-text="'‹'"
            :next-text="'›'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          ></paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterDatepicker from "@/components/filter-datepicker/FilterDatepicker.vue";
import BarVertical from "@/components/charts/BarVertical.vue";
import HeaderGraphic from "@/components/header-graphic/HeaderGraphic.vue";
import StatusUpdateDados from "@/components/status-update-dados/StatusUpdateDados.vue";
import { numberToReal } from "@/utils/money";
import { getMedias, getCSV } from "@/api/midiaSource";
import { fetchOrdersBilling, fetchCoupons, getCouponsCSV } from "@/api/customers";

export default {
  components: {
    BarVertical,
    HeaderGraphic,
    FilterDatepicker,
    StatusUpdateDados,
  },
  filters: {
    numberToReal
  },
  data() {
    return {
      graphicBarData: {},
      tableData: {
        title: {
          top: "Tabela",
          name: "Detalhamento",
        },
      },
      couponTableData: {
        title: {
          top: "Tabela",
          name: "Detalhamento",
        },
      },
      perPage: 15,
      currentPage: 1,
      start: null,
      end: null,
      typeMedia: "Source",
      titleGraphic: "Origem",
    };
  },
  computed: {
    ...mapState({
      ordersBilling: (state) => state.customers.ordersBilling,
      coupons: (state) => state.customers.coupons,
      midiaSource: state => state.midiaSource,
      accountActive: state => state.accounts.accountActive,
    }),
    loadGraphicBar() {
      const labels = [];
      const data = [];

      if (this.midiaSource.media.data) {
        this.midiaSource.media.data.map(line => {
          labels.push(line.name);
          data.push(line.valor_vendido);
        });
      }

      let newData = {
        title: {
          top: "Receita total",
          name: this.titleGraphic,
        },
        legenda: [],
        datacollection: {
          labels,
          datasets: [
            {
              barThickness: 16,
              lineTension: 0,
              borderWidth: 1,
              borderColor: "#FD3064",
              pointBackgroundColor: "#FD3064",
              backgroundColor: "#FD3064",
              data,
            },
          ],
        },
      };

      return newData;
    },
    loadTable() {
      const items = [];
      if (this.midiaSource.media.data) {
        this.midiaSource.media.data.map(line => {
          let obj = {};
          if (this.typeMedia != "Source") {
            obj.nome = line.name
          }
          obj["UTM Source"] = line.utmSource
          obj["Maior pedido"] = numberToReal(line.maior_pedido)
          obj["Menor pedido"] = numberToReal(line.menor_pedido)
          obj["Quantidade de pedidos"] = line.qtd_pedidos
          obj["Receita"] = numberToReal(line.valor_vendido)
          obj["ticket médio"] = numberToReal(line.ticket_medio)
          items.push(obj);
        });
      }

      let data = {
        items,
      };
      return data;
    },
    loadCouponTable() {
      const items = [];
      if (this.coupons.items && this.coupons.items.data) {
        this.coupons.items.data.map((line) => {
          items.push({
            Cupom: line.coupon,
            "Maior pedido": numberToReal(line.maior_pedido),
            "Menor pedido": numberToReal(line.menor_pedido),
            "Qtde. de pedidos": line.qtd_pedidos,
            Receita: numberToReal(line.valor_vendido),
            "Ticket médio": numberToReal(line.ticket_medio),
          });
        });
      }

      let data = {
        title: {
          top: "",
          name: "Ranking dos Cupons",
        },
        items,
      };

      return data;
    },
  },
  mounted() {
    this.getMedias(this.start, this.start, this.perPage, this.currentPage)(this.typeMedia);
    this.fetchCoupons(this.start, this.end, this.perPage, this.currentPage);
    this.fetchOrdersBilling(this.start, this.end);
  },
  methods: {
    fetchOrdersBilling,
    fetchCoupons,
    getCouponsCSV,
    getMedias,
    getCSV,
    setMedia(type) {
      this.typeMedia = type;

      console.log('this.typeMedia', this.typeMedia);
      
      if (this.typeMedia == "Coupon") {
        this.fetchCoupons(this.start, this.end, this.perPage, this.currentPage);
        this.fetchOrdersBilling( this.start, this.end);
        return (this.titleGraphic = "Cupons");
      }

      this.getMedias(this.start, this.end, this.perPage, this.currentPage)(this.typeMedia);

      if (this.typeMedia == "Source") {
        return (this.titleGraphic = "Origem");
      }
      if (this.typeMedia == "Campaign") {
        return (this.titleGraphic = "Campanha");
      }
      if (this.typeMedia == "Medium") {
        return (this.titleGraphic = "Mídia");
      }
      if (this.typeMedia == "Partner") {
        return (this.titleGraphic = "Parceiro");
      }
    },
    setDataFilter({ start, end }) {
      (this.start = start), (this.end = end);
      if (this.typeMedia == "Coupon") {
        this.fetchCoupons( start, end, this.perPage, this.currentPage);
        this.fetchOrdersBilling( start, end);
      } else {
        this.getMedias(start, end, this.perPage, this.currentPage)(this.typeMedia);
      }
    },
    downloadCSV() {
      if (this.typeMedia == "Coupon") {
        this.getCouponsCSV(this.start, this.end);
      } else {
        this.getCSV(this.start, this.end)(this.typeMedia);
      }
    },
  },
  watch: {
    coupons(data) {
      console.log('watch coupons', data);
    },
    currentPage(newPage) {
      this.currentPage = newPage;
      if (this.typeMedia == "Coupon") {
        this.fetchCoupons( this.start, this.end, this.perPage, this.currentPage);
        this.fetchOrdersBilling( this.start, this.end);
      } else {
        this.getMedias(this.start, this.end, this.perPage, this.currentPage)(this.typeMedia);
      }
    },
    accountActive(data) {
      if (data) {
        if (this.typeMedia == "Coupon") {
          this.fetchCoupons( this.start, this.end, this.perPage, this.currentPage);
          this.fetchOrdersBilling( this.start, this.end);
        } else {
          this.getMedias(this.start, this.end, this.perPage, this.currentPage)(this.typeMedia);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.media-page {
  .box-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    &__tabs {
      display: flex;
      border-bottom: 1px solid #bababa;
      @media only screen and (max-width: 991px) {
        margin-top: 20px;
      }
    }
    &__tabs-item {
      margin-right: 20px;
      text-transform: uppercase;
      font-size: 14px;
      position: relative;
      padding-bottom: 10px;
      cursor: pointer;
      @media only screen and (max-width: 991px) {
        font-size: 12px;
        text-align: center;
      }
      &:hover {
        color: $red;
      }
      &:last-child {
        margin-right: 0;
      }
      &.--active {
        position: relative;

        &::before {
          content: "";
          width: 100%;
          height: 2px;
          position: absolute;
          bottom: -1px;
          background: $red;
          @media only screen and (max-width: 991px) {
            left: 0;
          }
        }
      }
      &.--title {
        font-weight: bold !important;
        &:hover {
          color: inherit;
        }
      }
    }
  }

  .categorias {
    height: 100%;
    .page-container {
      margin: 30px 0;
    }
  }

  .small {
    max-width: 600px;
    margin: 150px auto;
  }

  .primary {
    display: flex;
    flex-wrap: wrap;
  }

  .box {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 44px;
    max-width: 100%;
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 18px;
    box-shadow: 0 3px 6px #c3d0e029;
    border-radius: 20px;
    &--chart {
      height: 400px;
    }
    &:nth-child(2) {
      margin-right: 0;
    }
    &__header {
      display: flex;
      justify-content: space-between;
    }
    .painel {
      position: relative;
      height: 300px;
      display: block;
      overflow-x: auto;
      &__empty {
        text-align: center;
        width: 100%;
        font-size: 16px;
        height: 100%;
        display: flex;
        color: $red !important;
        align-items: center;
        justify-content: center;
      }
      .table {
        th[aria-colindex="3"],
        td[aria-colindex="3"],
        th[aria-colindex="4"],
        td[aria-colindex="4"],
        th[aria-colindex="6"],
        td[aria-colindex="6"],
        th[aria-colindex="7"],
        td[aria-colindex="7"] {
          white-space: nowrap;
          text-align: right;
        }
        th[aria-colindex="5"],
        td[aria-colindex="5"] {
          white-space: nowrap;
          text-align: center;
        }
      }
    }
    &.table {
      margin: 0;
      max-width: calc(100%);
      height: auto;
      &:after {
        content: "";
        display: block;
      }
      .painel {
        height: auto;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .media-page {
    .box-filter {
      flex-direction: column-reverse;
    }
    .box {
      max-width: 100%;
      margin-top: 30px;
      margin-right: 0;
      padding: 25px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>
