import client from "@/api";
import store from "@/store";
import { _rotaVtexType, _rotaMediaGetCSV } from "@/api/_caminhosApi";

export function getCSV(from, to) {
  return function(type) {
    store.commit("interacoes/SET_LOADING", true);
    client
      .get(_rotaMediaGetCSV(type), {
        params: {
          from,
          to,
        },
        responseType: "blob",
      })
      .then(resp => {
        if (resp.status === 200) {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "media.csv");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(erro => {
        console.log(erro);
      })
      .then(() => {
        store.commit("interacoes/SET_LOADING", false);
      });
  };
}

export function getMedias(from, to, qtd, page) {
  return function(type) {
    store.commit("interacoes/SET_LOADING", true);
    store.commit("midiaSource/RESET_MEDIA", null);
    client
      .get(`${_rotaVtexType(type)}`, {
        params: {
          from,
          to,
          per_page: qtd,
          page: page,
        },
      })
      .then(resp => {
        if (resp.status === 200) {
          store.commit("midiaSource/SET_MEDIA", resp.data);
        }
      })
      .catch(erro => {
        console.log(erro);
      })
      .then(() => {
        store.commit("interacoes/SET_LOADING", false);
      });
  };
}
